<template>
  <div class="settlementCenter">
    <h3>开单</h3>
    <div class="topi">
      <div class="ff">
        <p>选择处方：</p>
        <el-select v-model="value" placeholder="请选择" @change="opbh">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="go(0)">结算</el-button>
    </div>

    <div v-if="item.S_OP_PrescriptionTypaName == 0">
      <div class="srA">
        <div>
          <ul class="content-sr mar-20">
            <li></li>
            <li>球镜</li>
            <li>柱镜</li>
            <li>轴向</li>
            <li>远用矫正视力</li>
            <li>ADD</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DSOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DCOD" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_XOD" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DVAOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_ADDOD" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr mar-20">
            <li>OS</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DSOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DCOS" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_XOS" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DVAOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_ADDOS" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
      </div>

      <div class="srA">
        <div>
          <ul class="content-sr mar-20">
            <li></li>
            <li>近用矫正视力</li>
            <li>瞳距</li>
            <li>瞳高</li>
            <li>凌镜</li>
            <li>基底</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_NVAOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PDOD" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PHOD" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_ArriseOD" placeholder="请输入内容"></el-input></li>
            <li>
              <el-select  disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_BasisOD" class="sr" filterable allow-create placeholder="请选择">
                <el-option
                    v-for="item in jd"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </li>
          </ul>
          <ul class="content-sr mar-20">
            <li>OS</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_NVAOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PDOS" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PHOS" placeholder="请输入内容"></el-input>mm</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_ArriseOS" placeholder="请输入内容"></el-input></li>
            <li>
              <el-select disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_BasisOS" class="sr" filterable allow-create placeholder="请选择">
                <el-option
                    v-for="item in jd"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="item.S_OP_PrescriptionTypaName == 2">
      <div class="srA">
        <div>
          <ul class="content-sr mar-20">
            <li></li>
            <li>球镜</li>
            <li>柱镜</li>
            <li>轴向</li>
            <li>近用视力矫正</li>
            <li>瞳距</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DSOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DCOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_XOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_VAOD" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PDOD" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr mar-20">
            <li>OS</li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DSOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_DCOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_XOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_VAOS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.S_OP_InspectionOrder180726.S_OP_IO_PDOS" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
        <el-button class="last" icon="el-icon-refresh" type="primary">转换处方</el-button>
      </div>
    </div>

    <div v-if="item.S_OP_PrescriptionTypaName == 6">
      <div class="srA">
        <div>
          <ul class="content-sr mar-20">
            <li></li>
            <li>球镜</li>
            <li>柱镜</li>
            <li>轴向</li>
            <li>视力</li>
            <li>基弧</li>
            <li>镜片直径</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OD_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OD_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OD_X" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OD_VA" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled  placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OD_DIA" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr mar-20">
            <li>OS</li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OS_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OS_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OS_X" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OS_VA" placeholder="请输入内容"></el-input></li>
            <li><el-input disabled  placeholder="请输入内容"></el-input></li>
            <li><el-input disabled v-model="item.CL_Order180626.CL_Order_OS_DIA" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sh"></div>
    <h3>订单处理</h3>
    <div class="ddcl">
      <el-button @click="go(1)" type="primary">订单处理</el-button>
    </div>


  </div>
</template>

<script>
export default {
  name: "settlementCenter",
  data() {
    return {
      value: '',
      cfValue: '1',
      options: [

      ],
      jd: [
        {
          value: '内',
          label: '内'
        },
        {
          value: '外',
          label: '外'
        },
        {
          value: '上',
          label: '上'
        },
        {
          value: '下',
          label: '下'
        },
      ],
      falx: [
        {
          value: '单光',
          label: '单光'
        },
        {
          value: '渐进',
          label: '渐进'
        },
        {
          value: '抗疲劳',
          label: '抗疲劳'
        },
        {
          value: '离焦',
          label: '离焦'
        },
        {
          value: '儿童多焦点',
          label: '儿童多焦点'
        },
        {
          value: '双光镜片',
          label: '双光镜片'
        },
      ],
      cfsfalx: [
        {
          value: 'SCL',
          label: 'SCL'
        },
        {
          value: 'RGP',
          label: 'RGP'
        },
        {
          value: 'OK镜片',
          label: 'OK镜片'
        },
      ],

      postData: [],
      cfarr: [],
      kjyy: {},

      ysArr: [],
      item: {S_OP_PrescriptionTypaName: {}},
      jsUrl: ''
    }
  },

  created() {
    // this.$store.commit('isShow/upBottom', false)
    if (!this.$store.state.users.CsUser && !this.$store.state.physicianVisits.xzConsulting) {
      return this.$alert('顾客登陆，选择咨询或者新建咨询')
      .then(res => {
        this.$router.push({path: '/csLanding'})
      })
    }
    this._api.expertClinic.getKd()
        .then(res => {
          if (res.GetPrescriptionTypeListResult && res.GetPrescriptionTypeListResult.length > 0) {
            let oparr = [], datas = res.GetPrescriptionTypeListResult
            for (let i = 0; i < datas.length; i++) {
              if (datas[i].S_OP_PrescriptionTypaName == '0') {
                oparr.push({
                  value: i,
                  label: '框架远用'
                })
              }else if (datas[i].S_OP_PrescriptionTypaName == '2') {
                oparr.push({
                  value: i,
                  label: '框架近用'
                })
              }else if (datas[i].S_OP_PrescriptionTypaName == '6') {
                oparr.push({
                  value: i,
                  label: '角膜接触镜'
                })
              }
            }
            this.cfarr = datas
            this.options = oparr
          }
        })
  },

  methods: {
    cfData(e) {
      // console.log(e)
    },


    addCf() {
      if (this.cfarr.length >= 3) {
        return this.$alert('不可再添加更多处方数据')
      }
      this.cfarr.push(this.ysArr[Number(this.cfValue)-1])
    },

    opbh(e) {
      this.item = this.cfarr[e]
    },

    go(type) {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登陆，请先登陆','提示')
            .then(res => {
              this.$router.push({path: '/employeesLanding'})
            })
      }
      if (type == 0) {
        if (!this.value  && this.value !== 0)  return this.$alert('请选择方案数据！', '提示')
      }
      let arr = this.$store.state.users.user.Company.ListCompanyConfig
      let yg =  this.$store.state.users.employees,
          csID = this.$store.state.users.CsUser.CustomerId,
          chosID = this.$store.state.physicianVisits.xzConsulting,
          uid = this.$store.state.users.user.UserId,
          shopID = this.$store.state.bases.shop.ShopId

      if (arr.length < 1) return this.$alert('暂无权限', '提示')

      if (arr && arr.length > 0) {
        let obj = ''
        let ERP_com = ''
        if (!yg.ListERPCode) {
          return this.$alert('暂无权限', '提示')
        }
        let yscode = yg.ListERPCode[0].ObjectCode?yg.ListERPCode[0].ObjectCode:''
        let s_id = type?'':this.cfarr[this.value].S_OP_ID
        if (type == 0) {
          obj = arr.filter(item => item.BCKey == 'ERP_SellURL')
        }else {
          obj = arr.filter(item => item.BCKey == 'ERP_URL')
        }
        ERP_com = arr.filter(item => item.BCKey == 'ERP_com')
        if (ERP_com && ERP_com.length > 0) {
          ERP_com = ERP_com[0].BCValue
        }
        if (!obj.length) {
          return this.$alert('暂无权限', '提示')
        }
        let url = obj[0].BCValue
        if(url) {
          url = url.replace('{ERP_com}', ERP_com)
          url =url.replace('{Expert_Code}', yscode)
          url =url.replace('{CustomerId}', csID)
          url =url.replace('{ChooseId}', chosID)
          url =url.replace('{S_OP_Id}', s_id)
          url =url.replace('{COAddUserId}', uid)
          url =url.replace('{COExpertId}', yg.ExpertId)
          url = url.replace('{COShopId}', shopID)
        }
        // if (type) {
        //   url = url.split('?')[0] + `?user_id=${yg.ListERPCode[0].ObjectCode}&login_type=visumall&CustomerId=${csID}&ChooseId=${chosID}&COAddUserId=${uid}&COExpertId=${yg.ExpertId}&COShopId=${shopID}`
        // }else {
        //   url = url.split('?')[0] + `?user_id=${yg.ListERPCode[0].ObjectCode}&login_type=visumall&CustomerId=${csID}&ChooseId=${chosID}&S_OP_Id=${this.cfarr[this.value].S_OP_ID}&COAddUserId=${uid}&COExpertId=${yg.ExpertId}&COShopId=${shopID}`
        // }
        window.open(url)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.settlementCenter {
  width: 80vw;
  height: 87vh;
  margin: 0 auto;
  font-size: 14px;
  overflow: hidden;
  overflow-y: auto;
}
.settlementCenter::-webkit-scrollbar { width: 0 !important }
.settlementCenter::-webkit-scrollbar { width: 0 !important }
h3 {padding-top: .4rem; border-bottom: 1px solid #000000;text-align: left;}
h4 {
  text-align: left;
  width: 1.5rem;
  padding: 0 .2rem;
}
.box {
  width: 100%;
  height: 20vh;
}
.hx {
  width: 100%;
  height: 5px;
  background: #bbbaba;
  margin: .15rem 0;
}
.clfa {
  display: flex;
  align-items: center;
  justify-content: start;
  .sr {
    margin: 0 .3rem;
  }
}
.mar-20{margin-top: 20px}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background: #efefef;
  padding: 10px 0;
  border-radius: 5px;
  .titles {
    padding-left: 40px;
  }
  .cz {padding-right: 40px}
}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 3em;
      //padding-right: .rem;
      justify-content: start;
      //margin-right: 5px;
    }
  }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-top: 32px;
  }
}
.meed {
  ul{
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 4vw;
      justify-content: start;
      margin-right: 0.05rem;
    }
  }
  .contentu {
    display: flex;
    justify-content: start;
    align-items: center;
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
    }
    .box {width: 8vw;}
    .left {
      margin-right: 0.4rem;
      .lefta {
        display: flex;
        align-items: center;
        p {width: 4vw;text-align: left;}
      }
    }
    .rights {
      display: flex;
      align-items: center;
      justify-content: start;
      .padd-40 {padding-right: 0.4rem;}
    }
  }
}
.bottom {
  margin: 0 auto;
  display: flex;
  justify-content: start;
  margin-top: 40px;
  .bzs {
    width: 95%;
  }
  p {width: 4em;text-align: left; padding-right: .2rem}
}


.topi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: .3rem;
  .ff {
    display: flex;
    align-items: center;
    justify-content: start;
  }
}

.sh {
  width: 100%;
  height: 4px;
  margin: 4vh 0;
  background: #9b9b9b;
}

.ddcl {
  display: flex;
  justify-content: start;
  padding-top: .2rem;
  padding-bottom: 6vh;
}
</style>